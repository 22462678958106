
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import FeaturesPage from './pages/Features';
import ContactPage from './pages/ContactPage';
import ResourcesPage from './pages/ResourcesPage';
import HomePage from './pages/HomePage';
import TeamsPage from './pages/TeamsPage';
import Footer from './components/Footer';
import NotFoundPage from './pages/404page';
import CareersPage from './pages/CareersPage';
import JobApplicationPage from './pages/JobApplication';
import About from './pages/About';
import FAQs from './pages/Faq';
function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} /> {/* Home page */}
          <Route path="*" element={<NotFoundPage />} /> {/* Home page */}
          <Route path="/features" element={<FeaturesPage />} /> {/* About page */}
          <Route path="/team" element={<TeamsPage />} /> {/* Services page */}
          <Route path="/contact" element={<ContactPage />} /> {/* Contact page */}
          <Route path="/resources" element={<ResourcesPage />} /> {/* Resources page */}
          <Route path="/careers" element={<CareersPage />} /> {/* Careers page */}
          <Route path="/about" element={<About />} /> {/* Careers page */}
          <Route path="/jobapplication" element={<JobApplicationPage />} /> {/* Careers page */}
          <Route path="/support" element={<FAQs/>} /> {/* Careers page */}
        </Routes>
        <Footer/>
      </Router>
    </>
  );
}

export default App;

