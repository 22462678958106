import React from 'react';
import './FeatureSection.css'; // External CSS for styling

const features = [
    {
        id: 6,
        title: "Mobile Development",
        description: "Build, deploy, and manage mobile applications effortlessly with Lumis' mobile development platform. Whether you're creating iOS or Android apps, our team enable seamless development, testing, and optimization."
        ,
        image: "https://getconflux.com/static/communicate-375375abf42555b3ea565f66e205674a.svg",
        alt: "Advanced Analytics"
    },

    {
        id: 1,
        title: "Web Development",
        description: "Build and scale web solutions effortlessly with Lumis. Our platform offers customizable templates, responsive designs, and robust backend support to ensure seamless experiences across all devices.",
        image: "https://getconflux.com/static/a8ad35e12e64647317334c38fface92f/ec873/filters.png",
        alt: "Public Ideaboard"
    },
    {
        id: 2,
        title: "System Development",
        description: "Streamline your system development with Lumis. We provide efficient, scalable, and secure systems, tailored to meet your business needs.",
        image: "https://getconflux.com/static/6a3734c0c157383e956f21aaa6e535d1/ec873/emotion-analysis.png",
        alt: "Emotion Analysis"
    },

    {
        id: 5,
        title: "Graphic & Web Design",
        description: "Create stunning visuals and user-friendly web designs with Lumis. Our platform provides responsive, aesthetically pleasing websites tailored to your brand.",
        image: "https://getconflux.com/static/collect-feedback-c49c008fefe44e0327de3eadd706bc67.svg", // Replace with actual image path
        alt: "Workflow Automation"
    },


];


const FeatureSection = () => {
    return (
        <div className="features-container">
            {features.map((feature, index) => (
                <div
                    className={`feature ${index % 2 === 0 ? 'image-left' : 'image-right'}`}
                    key={feature.id}
                >
                    <div className="feature-image">
                        <img src={feature.image} alt={feature.alt} />
                    </div>
                    <div className="feature-text">
                        <h3>{feature.title}</h3>
                        <p>{feature.description}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default FeatureSection;
