import React from 'react'
import Accordion from '../components/Accordion'
import GetInTouchComponent from '../components/GetInTouchComponent'
import QuestionsAnswers from '../components/QuestionAnswer'

export default function FAQs() {
  return (
    <div>
      <QuestionsAnswers/>
      <Accordion/>
      <GetInTouchComponent/>
    </div>
  )
}
