import React from 'react';
import './QuestionAnswer.css';
import { HiChatBubbleOvalLeftEllipsis } from "react-icons/hi2";

const QuestionsAnswers = () => {
    return (
        <div className="qa-container">
            <div className="qa-text">
                <h1>
                    YOU HAVE QUESTIONS. <br />
                    <span>WE HAVE </span>
                    <span className="qa-icon">
                    <HiChatBubbleOvalLeftEllipsis />
                    </span>
                    <span> ANSWERS.</span>
                </h1>
            </div>
        </div>
    );
};

export default QuestionsAnswers;
