import React from 'react';

const VerticalDottedLine = () => {
  const lineStyle = {
    width: '2px', // Line thickness
    height: '100px', // Total height
    backgroundImage: 'linear-gradient(grey 5px, transparent 5px)', // Dots with spacing
    backgroundSize: '2px 10px', // Width and spacing of dots
    backgroundRepeat: 'repeat-y', // Repeat vertically
    margin: '0 auto',
    marginBottom:'1rem'
  };

  return <div style={lineStyle}></div>;
};

export default VerticalDottedLine;
