import React from 'react';
import './CoreTeam.css';
import { MdStars } from "react-icons/md";

const teamMembers = [
  {
    name: 'Mr. Lovsey',
    role: 'Creative',
    image: 'https://plus.unsplash.com/premium_photo-1682141308411-29d7662447cb?q=80&w=2072&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    description: "When L isn't enjoying a newly brewed tipple, he heads down into a book or rolling the dice at a medieval tavern.",
    linkedin: 'https://linkedin.com/in/edmund'
  },
  {
    name: 'Ms.  Monteith',
    role: 'Communications',
    image:'https://images.unsplash.com/photo-1698891668251-fd6974673f82?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    description: "Monteith loves spending time in her kitchen surrounded by books, good friends, and a trusty cup of tea.",
    linkedin: 'https://linkedin.com/in/elsa'
  },
  {
    name: 'Mr. M. Ijakh',
    role: 'Designs',
    image: 'https://images.unsplash.com/photo-1559418162-0d309d8d10a3?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    description: "Ijakh loves plant-based cooking, walking in nature, and evenings with friends playing games.",
    linkedin: 'https://linkedin.com/in/holly'
  },
  {
    name: 'Ms. Taiyo',
    role: 'Co-founder',
    image: 'https://images.unsplash.com/photo-1613005341945-35e159e522f1?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    description: "Taiyo enjoys cold-water swimming and experimenting with seasonal harvests from the community garden.",
    linkedin: 'https://linkedin.com/in/sara'
  }
];

const CoreTeam = () => {
  return (
    <div className="core-team-section">
      <h5>Core Team</h5>
      <div className="team-container">
        {teamMembers.map((member, index) => (
          <div className="team-member" key={index}>
            <img src={member.image} alt={member.name} className="team-member-img"loading='lazy' />
            <div className="team-member-info">
              <h3>{member.name}</h3>
              <div className="position-container">
                <p className="role">{member.role}</p>
                <a href target="_blank" rel="noopener noreferrer">
                  <MdStars />
                </a>
              </div>
              <p>{member.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CoreTeam;
