import React, { useState } from 'react';
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdKeyboardArrowUp } from "react-icons/md";
import './Accordion.css'
const faqs = [
  {
    question: 'What kind of software development services do you offer?',
    answer: 'We provide end-to-end web, mobile, and system development services, including design, development, deployment, and ongoing maintenance.',
  },
  {
    question: 'How long does it take to develop a custom software solution?',
    answer: 'The timeline depends on the project complexity and requirements. Typically, projects range from 4 weeks to several months.',
  },
  {
    question: 'How do you ensure the quality of your software?',
    answer: 'Our team follows industry best practices, conducts thorough testing, and uses CI/CD pipelines to ensure the highest quality in every project.',
  },
  {
    question: 'Do you provide post-launch support?',
    answer: 'Yes, we provide post-launch support and maintenance to address any issues and ensure your software runs smoothly.',
  },
  {
    question: 'Can you integrate third-party APIs or services?',
    answer: 'Absolutely! We are experienced in integrating various third-party APIs and services to enhance your software’s functionality.',
  },
  {
    question: 'What is your pricing model for software projects?',
    answer: 'Our pricing depends on the project’s complexity and scope. We offer fixed-price, hourly, or retainer-based pricing models.',
  },

];


const Accordion = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="accordion">
      <h2>Frequently Asked Questions</h2>
      {faqs.map((faq, index) => (
        <div key={index} className="accordion-item">
          <div
            className="accordion-header"
            onClick={() => toggleAccordion(index)}
          >
            <h4>{faq.question}</h4>
            <span>{activeIndex === index ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}</span>
          </div>
          {activeIndex === index && (
            <div className="accordion-body">
              <p>{faq.answer}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Accordion;
