import React from 'react'
import './About.css'
import AboutHeader from '../components/AboutHeader'
import AboutFeatures from '../components/AboutFeatures'
export default function About() {
    return (
        <div>
            <AboutHeader/>
            <AboutFeatures/>
        </div>
    )
}