import './AboutHeader.css';

const AboutHeader = () => (
  <div className="header">
    <div className="background-image"></div>
    <h1 className="heading">
      Lumis has existed for 7 years,<br />
      during which we've managed to help over 100 businessses achieve their software needs.
    </h1>
    <hr />
  </div>
);

export default AboutHeader;
