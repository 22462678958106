import './AboutFeatures.css';
import VerticalDottedLine from './VerticalDottenLine';

const AboutFeatures = () => (
  <>
    <div className="features">
      <h1 className='about-header'>Staying true to out values</h1>
      <p>The values that guide us on our missions.</p>
    </div>
    <VerticalDottedLine />
    <div className='feature'>
      <img src='https://img.icons8.com/?size=100&id=63964&format=png&color=000000' alt='accessibility' width={200}></img>
      <div className='feature-content'>
        <h2>Accesibility</h2>
        <h4>Open to everyone</h4>
        <p>Our main belief is that anyone should be able to invest in property development projects. Thats's why Lumis is open for everyone.</p>
      </div>
    </div>
    <VerticalDottedLine />
    <div className='feature'>
      <div className='img-container'>
        <img src='https://img.icons8.com/?size=100&id=L8u73GfZ6PD4&format=png&color=000000' alt='accessibility' width={200}></img>
      </div>
      <div className='feature-content'>
        <h2>Transparency</h2>
        <h4>Sharing everything.</h4>
        <p>A transparent approach to everything. Nothing is hidden form our clients, we want them to be a part of every single step of the way</p>
      </div>
    </div>
    <VerticalDottedLine />
    <div className='feature'>
      <img src='https://img.icons8.com/?size=100&id=yXBtLTk0F85T&format=png&color=000000' alt='simplicity' width={200}></img>
      <div className='feature-content'>
        <h2>Simplicity</h2>
        <h4>Keeping it Simple</h4>
        <p>We believe software services should be available to everyone. That's why our process is simple therefore nothing prevents you from contactinng us.</p>
      </div>
    </div>
    <VerticalDottedLine />
    <div className='feature'>
      <div className='img-container'>
        <img src='https://img.icons8.com/?size=100&id=2-Cf_3f12bcT&format=png&color=000000' alt='community' width={200}></img>
      </div>
      <div className='feature-content'>
        <h2>Community</h2>
        <h4>We are one.</h4>
        <p>Out business is focused on community. While building new softwares we make sure everyone has the opportunity to meet their co-investors</p>
      </div>
    </div>
    <VerticalDottedLine />
  </>
);

export default AboutFeatures;