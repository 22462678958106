import React from 'react';
import './GetInTouchComponent.css';

export default function GetInTouchComponent() {
    return (
        <div>
            <div className="component-container">
                <div className="avatar-container"></div>
                <div className="confirm-questions">
                    <h4>Still have questions?</h4>
                    <p>Can't find the answer to what you're looking for? Please contact our friendly team.</p>
                </div>
                <div className="get-in-touch-btn">
                    <p>Get in touch</p>
                </div>
            </div>
        </div>
    );
}
